import React, { useEffect, useState } from "react";
import Loader from "../../component/loader/Loader";
import { jsPDF } from "jspdf";
import { Pie } from "react-chartjs-2";
import { FaDownload } from "react-icons/fa6";
import "jspdf-autotable";
import logo from "../../assests/image/wishi.png";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// Registering the required elements of Chart.js
ChartJS.register(ArcElement, Tooltip, Legend);

const InvoiceOverview = () => {
    const [usersData, setUsersData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState(""); // Search query state
    const [chartData, setChartData] = useState({
        labels: ["Amount Received", "GST Received"],
        datasets: [
            {
                label: "Amount and GST",
                data: [0, 0], // Default data, will be updated after fetching data
                backgroundColor: ["#4caf50", "#ff9800"], // Colors for the segments
            },
        ],
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await fetch("https://www.wishgeekstechserve.com/api/get-selected-details", {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }

                const data = await response.json();
                setUsersData(data.data.transactions || []); // Use `data.data` to access the processed transactions
                console.log(data.data.transactions);


                // Calculate total amounts and GST
                const totalAmountReceived = data.data.transactions.reduce((acc, transaction) => acc + (transaction.paidAmount - ((transaction.paidAmount || 0) * 0.18) || 0), 0);
                const totalGSTReceived = data.data.transactions.reduce((acc, transaction) => acc + ((transaction.paidAmount || 0) * 0.18), 0);

                // Update chart data
                setChartData((prevData) => ({
                    ...prevData,
                    datasets: [
                        {
                            ...prevData.datasets[0],
                            data: [totalAmountReceived, totalGSTReceived],
                        },
                    ],
                }));
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    // Filtered data based on search query
    const filteredUsers = usersData.filter((transaction) =>
        transaction.transactionId.toLowerCase().includes(searchQuery.toLowerCase()) ||
        transaction.userId.email.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const downloadInvoice = (transaction) => {
        const doc = new jsPDF();

        // Add Logo (replace the URL with your logo path or base64 data)
        const logoUrl = logo; // Replace with actual path or base64 data
        const logoWidth = 60;
        const logoHeight = 20;
        const logoX = (doc.internal.pageSize.width - logoWidth) / 2; // Center the logo horizontally
        doc.addImage(logoUrl, "PNG", logoX, 10, logoWidth, logoHeight); // Logo at top-center

        // Define 'To' and 'From' addresses
        const billingAddress = `To: \n${transaction.userId.name}\n${transaction.userId.address}`;
        const companyAddress = `From: \nWishgeeks TechServe\n1234 Company Address\nCity, Country`;

        // Add 'To' and 'From' details
        doc.setFontSize(12);

        // 'To' address on extreme left
        doc.text(billingAddress, 10, 40);

        // 'From' address on extreme right
        const pageWidth = doc.internal.pageSize.width;
        const rightMargin = 10;
        doc.text(companyAddress, pageWidth - rightMargin, 40, { align: "right" });

        // Title
        doc.setFontSize(18);
        doc.text("Invoice", pageWidth / 2, 60, { align: "center" }); // Invoice title centered

        // Transaction Details with table formatting
        doc.setFontSize(12);

        // Prepare data for the table
        const gstAmount = (transaction.paidAmount * 0.18).toFixed(2); // GST Calculation
        const totalAmount = (transaction.paidAmount * 1.18).toFixed(2); // Total Amount Calculation
        const netAmount = (transaction.paidAmount - gstAmount).toFixed(2); // Net Amount (paidAmount - GST)

        const details = [
            ["Transaction ID", transaction.transactionId],
            ["Project Name", transaction.projectName],
            ["User Email", transaction.userId.email],
            ["Date", new Date(transaction.transactionDate).toLocaleDateString()],
            ["Amount", `Rs. ${netAmount}`],
            ["GST (@18%)", `Rs. ${gstAmount}`],
            ["Total", `Rs. ${totalAmount}`],
        ];

        // Add table using jsPDF-Autotable
        doc.autoTable({
            head: [["Label", "Value"]],
            body: details,
            startY: 70, // Position the table below the title and logo
            theme: "grid", // Optional theme (grid, plain, striped)
            headStyles: { fillColor: [0, 112, 192] }, // Custom styles for table headers
            margin: { left: 10, right: 10 },
        });

        // Save the document
        doc.save(`Invoice_${transaction.transactionId}.pdf`);
    };

    return (
        <>
            <div className="container h-[100vh] overflow-y-auto bg-white p-4">
                <div className="flex items-center justify-between mb-4 mt-3 border-b border-gray-300">
                    <h1 className="text-3xl font-medium mt-12 text-gray-500">Invoice Details</h1>
                </div>

                {loading ? (
                    <Loader />
                ) : (
                    <div>
                        {/* Pie Chart */}
                        <div className="mb-8">
                            <div className="flex items-center space-x-4"> {/* Flex container with spacing between chart and text */}
                                <div className="relative">
                                    <Pie
                                        data={chartData}
                                        options={{
                                            responsive: true,
                                            plugins: {
                                            },
                                            aspectRatio: 1, // This ensures a square aspect ratio
                                            maintainAspectRatio: false, // Allow explicit height and width to take precedence
                                        }}
                                        width={200}  // Set the width of the pie chart
                                        height={200}  // Set the height of the pie chart
                                    />
                                </div>
                                <div className="text-lg font-medium text-gray-800">
                                    <span className="font-bold">Total Amount Received (Including GST): </span> ₹{(chartData.datasets[0].data[0] + chartData.datasets[0].data[1]).toFixed(2)} {/* Display the total amount including GST */}
                                </div>
                            </div>
                        </div>
                        {/* Search Input */}
                        <div className="mb-4">
                            <input
                                type="text"
                                className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                placeholder="Search by Transaction ID or Email"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </div>

                        {/* Table */}
                        <div className="overflow-x-auto">
                            <table className="min-w-full divide-y divide-gray-200 bg-white mb-5">
                                <thead className="bg-[#338dfb] text-white">
                                    <tr>
                                        <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border">
                                            Date
                                        </th>
                                        <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border">
                                            Transaction Id
                                        </th>
                                        <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border">
                                            Project Name
                                        </th>
                                        <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border">
                                            User Email
                                        </th>
                                        <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border">
                                            Paid Amount (₹)
                                        </th>
                                        <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border">
                                            GST (@18%) (₹)
                                        </th>
                                        <th className="px-6 py-3 text-xs font-bold uppercase tracking-wider text-left border">
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {filteredUsers.length > 0 ? (
                                        filteredUsers.map((transaction, index) => (
                                            <tr key={index}>
                                                <td className="px-6 py-4 text-xs border font-medium">
                                                    {
                                                        transaction.transactionDate ? (
                                                            <>
                                                                {new Date(Number(transaction.transactionDate)).toLocaleDateString("en-US", {
                                                                    year: "2-digit",
                                                                    month: "2-digit",
                                                                    day: "2-digit",
                                                                })}{" "}
                                                                {new Date(Number(transaction.transactionDate)).toLocaleTimeString("en-US", {
                                                                    hour: "2-digit",
                                                                    minute: "2-digit",
                                                                })}
                                                            </>
                                                        ) : (
                                                            "Invalid Date"
                                                        )
                                                    }
                                                </td>
                                                <td className="px-6 py-4 text-xs border font-bold">
                                                    {transaction.transactionId}
                                                </td>
                                                <td className="px-6 py-4 text-xs border font-bold">
                                                    {transaction.projectName}
                                                </td>
                                                <td className="px-6 py-4 text-xs border font-medium">
                                                    {transaction.userId.email || "N/A"}
                                                </td>
                                                <td className="px-6 py-4 text-xs border font-medium">
                                                    {transaction.paidAmount || "0"}
                                                </td>
                                                <td className="px-6 py-4 text-xs border font-medium">
                                                    {transaction.paidAmount * 0.18}
                                                </td>
                                                <td className="px-6 py-4 text-xs border font-medium">
                                                    <button className="bg-blue-500 text-white p-2 rounded-lg" onClick={() => downloadInvoice(transaction)}>Download <FaDownload className="inline" /></button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td
                                                colSpan="6"
                                                className="px-6 py-4 text-xs text-gray-500 text-center"
                                            >
                                                No data available
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default InvoiceOverview;
