import React, { useEffect, useState } from 'react'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { FaFileContract } from "react-icons/fa6";
import TextField from '@mui/material/TextField';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import InputAdornment from '@mui/material/InputAdornment';
import LinkIcon from '@mui/icons-material/Link';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
const QueryPopupModal = ({ data, closeModal, ticketId, userId }) => {
    const [queryDetails, setQueryDetails] = useState({})
    const [alignment, setAlignment] = useState('Pending');
    const [isExtraAddonVisible, setIsExtraAddonVisible] = useState({addonsModal:false, contractModal:false})
    const [files, setFiles] = useState({})

    const [item, setItem] = useState({ name: '', price: '' });
    const [items, setItems] = useState([]);

    const handleExtraAddOns = () => {
        const { name, price } = item;
        if (name.trim() && price.trim() && !isNaN(price) && Number(price) > 0) {
            const newItem = {
                name: name,
                price: parseFloat(price),
            };
            setItems([...items, newItem]);
            setItem({ name: '', price: '' });
        } else {
            alert("Please enter a valid item name and a positive price.");
        }
    };

    const onDelete = (index) => {
        const updatedItems = items.filter((_, i) => i !== index);
        setItems(updatedItems);
    };

    const total = items.reduce((acc, item) => acc + item.price, 0);
    useEffect(() => {
        setQueryDetails(data)
        setAlignment(data.status)
    }, [])


    const handleChange = (e, newAlignment) => {
        setAlignment(newAlignment);
        setQueryDetails({ ...queryDetails, status: newAlignment })
    };
    const handleSubmit = async (e) => {
        e.preventDefault()

        try {
            if (Object.keys(data).length > 0) {
                const { serviceTicketId, userId, _id, createdAt, ...updatableData } = queryDetails

                const response = await fetch(`https://www.wishgeekstechserve.com/api/query/${data._id}`, {
                    method: 'PATCH',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        ...updatableData,
                        addons: items,
                        addonTotal: total
                    }),
                });
                if (!response.ok) {
                    throw new Error("Failed to fetch query tickets data");
                }
                closeModal()
            } else {
                const response = await fetch('https://www.wishgeekstechserve.com/api/raise-query', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        ...queryDetails, addons: items, addonTotal: total, serviceTicketId: ticketId, userId: userId
                    }),
                });
                if (!response.ok) {
                    throw new Error("Failed to fetch query tickets data");
                }
                const data = await response.json();
                closeModal()
            }
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <div className="bg-white rounded-lg shadow-lg p-4 max-w-2xl h-[50%] lg:w-[65%] xl:h-[90%] overflow-y-auto w-full relative border border-black">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Edit Query</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="block text-gray-700" htmlFor="title">Title</label>
                    <input
                        type="text"
                        id="title"
                        value={queryDetails.queryDetails}
                        onChange={(e) => setQueryDetails({ ...queryDetails, queryDetails: e.target.value })}
                        className="mt-1 block w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Enter issue title"
                    />
                </div>
                <div className=' absolute top-6 right-6'>
                    <label htmlFor="Status" className='block mb-1'> Status</label>
                    <ToggleButtonGroup
                        size='small'
                        variant="contained"
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={handleChange}
                        aria-label="Status"
                    >
                        <ToggleButton value='Open'>Open</ToggleButton>
                        <ToggleButton value='In Progress'>In Progress</ToggleButton>
                        <ToggleButton value='Closed'>Closed</ToggleButton>
                    </ToggleButtonGroup>

                </div>

                {/* <div className='my-3'>


                </div> */}
                <div className='my-3 flex justify-between '>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '60%' }}>
                        <TextField id="input-with-sx" className='w-full' placeholder='https://figma.com/uwKEYsdks/uidyuw' label="URL" variant="outlined" size='small' value={queryDetails.url} onChange={e => setQueryDetails({ ...queryDetails, url: e.target.value })} />
                        <LinkIcon sx={{ color: 'action.active', ml: 0.5 }} />
                    </Box>

                    <Button
                        component="label"
                        role={undefined}
                        variant="outlined"
                        tabIndex={-1}
                        startIcon={<CloudUploadIcon />}
                    >
                        Upload files
                        <VisuallyHiddenInput
                            type="file"
                            onChange={(event) => setFiles(event.target.files)}
                            multiple
                        />
                    </Button>

                </div>
                <div className="">
                    <label className="block text-gray-700" htmlFor="issue">Issue</label>
                    <textarea
                        id="issue"
                        rows="3"
                        value={queryDetails.notes}
                        onChange={e => setQueryDetails({ ...queryDetails, notes: e.target.value })}
                        className="mt-1 block w-full border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Describe the issue"
                    ></textarea>
                </div>
                <Button variant="text" onClick={() => setIsExtraAddonVisible({...isExtraAddonVisible, addonsModal:true})}  >Add extra Pages</Button>
                <Button variant="text" onClick={() => setIsExtraAddonVisible({...isExtraAddonVisible, addonsModal:true})}>Add extra requirements</Button>
                <Button variant="text" onClick={() => setIsExtraAddonVisible({...isExtraAddonVisible, addonsModal:true})}>Add Miselenious</Button>

                {
                    isExtraAddonVisible.addonsModal &&
                    <>
                        <div className='flex gap-x-4 items-center max-w-sm'>
                            <TextField
                                size='small'
                                label="Item name"
                                variant="filled"
                                value={item.name}
                                onChange={(e) => setItem({ ...item, name: e.target.value })}
                                className='w-2/3'
                            />
                            <TextField
                                size='small'
                                label="Price"
                                variant="outlined"
                                type="number"
                                value={item.price}
                                onChange={(e) => setItem({ ...item, price: e.target.value })}
                                className='w-1/3'
                            />
                            <Button variant="text" onClick={handleExtraAddOns}>
                                <CheckIcon color="green" />
                            </Button>
                        </div>
                        <div className='w-[50%] pl-5 mt-4 border-gray-200'>
                            {items.map((item, index) => (
                                <div key={index} className='w-full flex items-center justify-between border-b '>
                                    <div className="flex-1 justify-between w-full flex md:mr-8">
                                        <span className="font-semibold">{item.name}</span>
                                        <span className="text-gray-600">{item.price.toFixed(2)}</span>
                                    </div>
                                    <button onClick={() => onDelete(index)}>
                                        <DeleteIcon color='secondary' fontSize='small' />
                                    </button>
                                </div>
                            ))}
                            <p className='flex items-center justify-between mt-2 md:mr-10'>
                                <span className='text-xl font-semibold'>Total</span>
                                <span className='text-base font-bold text-blue-500'>{total.toFixed(2)}</span>
                            </p>
                        </div>

                    </>
                }

                <button
                    className="bg-orange-500 text-white px-4 py-2 rounded-lg hover:bg-orange-700 transition-colors duration-300 w-fit absolute bottom-2 left-6"
                    onClick={()=>setIsExtraAddonVisible({...isExtraAddonVisible, contractModal:true})}
                >
                    Prepare Contract <FaFileContract className="inline ml-2" />
                </button>

                <div className="flex justify-end absolute bottom-2 right-6">
                    <button
                        type="button"
                        className="mr-2 px-4 py-2 bg-gray-300 text-gray-800 rounded-lg hover:bg-gray-400 transition duration-200"
                        onClick={closeModal}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-200"
                    >
                        Save
                    </button>
                </div>

            </form >
        </div >

    )
}

export default QueryPopupModal